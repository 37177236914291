export default {
  async insert (Vue, formData, idpresupuestocli) {
    const resp = await Vue.$api.call(
      'lpresupuestocli.insert',
      {
        values: {
          idpresupuestocli,
          idarticulo: formData.articulo.idarticulo,
          unidades: formData.unidades,
          codigo: formData.codigo,
          descripcion: formData.descripcion,
          inf_comercial: formData.inf_comercial,
          observacion: formData.observacion,
          opcional: formData.opcional,
        },
      },
    )
    return resp.data.result.dataset[0]
  },
}
